.footer{
    /* border: 2px solid red; */
    height: 12vh;
    background: #333333;
}
.footer-container{
    /* border: 2px solid blue; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.footer-text p{
    /* border: 2px solid orange; */
    color: white;
    font-size: 1.3rem;
    font-weight: bold;
}
.footer-text a{
    text-decoration: none;
    color: white;
    font-size: 1.2rem;
}