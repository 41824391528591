.icons{
    /* border: 2px solid red; */
    padding: 10rem;
    
}
.icons-container{
    /* border: 2px solid blue; */
    display: flex;
}


/* ******* Image Area******** */
.icons-image{
    /* border: 2px solid red; */
    width: 70rem;
    max-width: 70rem;
    display: flex;
    flex-direction: row-reverse;
}
.icon-image01{
width: 50rem;
height: 70rem;
max-width: 50rem;

}
.icon-image01 img {
width: 100%;
height: 100%;
max-width: 100%;
object-fit: cover;
object-position: center;
}

.icon-image02{
width: 30rem;
height: 50rem;
max-width: 30rem;
/* border: 2px solid black; */
margin-top: 40rem;
margin-right: -20rem;

}
.icon-image02 img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
    object-position: left;
}

/* *********** Icons Text Area*********** */
.icons-text{
    /* border: 2px solid red; */
    width: 70rem;
    max-width: 70rem;
    /* display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between; */
    padding: 5rem;    
    
}
.icon-flex{
    /* border: 2px solid orange; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;    
    gap: 5rem;
    
}
.icons-text h1{
    font-size: 2rem;
    /* border: 2px solid red; */
    text-align: center;
    text-transform: uppercase;
    font-weight: lighter;
    margin-top: 2rem;
}
.icon-name{
    
    
    font-size: 5rem;
    /* border: 2px solid green; */
    text-align: center;
    align-items: center;
    width: 20rem;
    max-width: 20rem;
    height: 20rem;
    /* margin: auto; */
}
/* media Queries */

@media(max-width:1400px){
    .icons-image{
        width: 50rem;
        max-width: 50rem;
    }
    .icon-image01{
        width: 40rem;
        height: 60rem;
        max-width: 40rem;
        
        }
        .icon-image02{
            
            height: 40rem;           
            
            }
    
    .icons-text{
              
        
        padding: 0rem;    
        
    }
}

@media(max-width:1150px){
    .icons-image{
        width: 40rem;
        max-width: 40rem;
    }
    .icon-image01{
        width: 30rem;
        height: 40rem;
        max-width: 30rem;
        
        }
        .icon-image02{
            /* width: -10rem; */
            height: 30rem;        
            
            margin-top: 30rem;
            margin-right: -20rem;
            

            
            }
    
    .icons-text{
              
        
        
        padding: 0rem;    
        
    }
    .icons-text h1{
        font-size: 1.8rem;
        margin-top: 1rem;
    }
    .icon-name{
    
    
        font-size: 3rem;
        
        width: 10rem;
        max-width: 10rem;
        height: 10rem;
        /* margin: auto; */
    }
}

@media(max-width:950px){
    .icons-container{
        flex-wrap: wrap;
    }
    .icon-flex{
        padding: 5rem;
    }

    .icons-image{
        width: 70rem;
        max-width: 70rem;
    }
    .icon-image02{
        width: 25rem;
        height: 30rem;
        max-width: 25rem;
        /* border: 2px solid black;
        margin-top: 40rem;
        margin-right: -20rem; */
        
        }
        .icon-name{
    
    
            font-size: 5rem;
            /* border: 2px solid green; */
            text-align: center;
            align-items: center;
            width: 15rem;
            max-width: 15rem;
            height: 15rem;
            /* margin: auto; */
        }
    
}

@media(max-width:650px){
    .icons-image{
        /* border: 2px solid red; */
        width: 100%;
        max-width: 100%;
        
    }
}

@media(max-width:450px){

    .icons-text{
        width: 100%;
        max-width: 100%;
        
    }
    .icons-image{
        /* border: 2px solid red; */
        width: 100%;
        max-width: 100%;
        
        
    }
   
        .icon-image02{
         
       margin-left: -10rem;
        }
        .icon-name{
            height: 10rem;
        }
}