.header{
    /* border: 2px solid black; */
    padding: 5rem 0;

    background: #F2F2F2;
    
}
.header-container{
    /* border: 2px solid blue; */
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-between;
    /* gap: 10rem; */
    
    
}
.header-textbox{
    /* border: 2px solid orange; */
    /* flex: 1 1 700px ; */
    max-width: 70rem;
    margin: auto;
    /* max-width: 400px; */
    
}
.header-textbox h2{
  
    font-size: 2rem;
    font-weight: lighter;
    margin-bottom: 3rem;
    margin-left: 10rem;
    
}
.header-textbox h1{
    font-size: 5rem;
   
    margin-bottom: 3rem;
    margin-left: 10rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: lighter;
}
.header-textbox p {
    
    font-size: 2rem;
    line-height: 3rem;
    margin-left: 15rem;
    /* border: 2px solid black; */
    
}
.header-imagebox{
    
    /* border: 2px solid green; */
    /* flex: 1 1 70rem; */
    max-width: 70rem;

  }

.box-wrapper{
    display: flex;
    /* border: 2px solid orange; */
    flex-direction: row-reverse;
    
}
.imagebox1{
    
    /* border: 2px solid red; */
    width: 50rem;
    max-width: 50rem;
    height: 60rem;
}

.imagebox1 img{
width: 100%;
height: 100%;
max-width: 100%;
object-fit: cover;
object-position: center;



}

.imagebox2{ 

    width: 30rem;
    height: 37rem;
    /* position: relative; */
    /* border: 2px solid black; */

    margin-top: 15rem;
    margin-right: -10rem;
    
}

.imagebox2 img{
    
    width: 100%;
    height: 100%;
    max-width: 100%;
    /* position: absolute; */
    object-fit: cover;
    object-position: center;
    /* z-index: 2;       */
}

/* Media Queries */
@media(max-width:1300px){

    .header-textbox h2{
  
        font-size: 1.8rem;
        
        margin-left: 5rem;
        
    }
    .header-textbox h1{
        font-size: 4rem;
       
        
        margin-left: 8rem;
        
    }
    .header-textbox p {
        
        font-size: 1.8rem;
        line-height: 2.5rem;
        margin-left: 12rem;
        /* border: 2px solid black; */
        
    }
}
    @media(max-width:1150px){
        
        .header-imagebox{
            width: 50rem;
            max-width: 50rem;
        }
        .imagebox2{ 

            /* width: 30rem; */
            height: 30rem;
            
        
        
            margin-top: 18rem;        
            
        }
        .imagebox1 img {
            object-position: left;
        }
      
}

@media(max-width:900px){
        
    .header-imagebox{
        width: 30rem;
        max-width: 30rem;
    }
    .imagebox2{ 

        /* width: 30rem; */
        height: 20rem;      
    
    
        margin-top: 15rem;        
        
    }
   
  
}

@media(max-width:650px){
   .header-container{
       flex-wrap: wrap;
   } 
   .header-textbox{
       padding:2rem;
       /* border: 2px solid blue; */
       
   }
   .header-textbox h2{
  
    
    
    margin-left: 0rem;
    
}
.header-textbox h1{
    font-size: 4rem;
   
    
    margin-left: 0rem;
    
}
.header-textbox p {
    
    font-size: 1.8rem;
    line-height: 2.5rem;
    margin-left: 0rem;
    /* border: 2px solid black; */
    width: 90%;   
    
}
.header-imagebox{
    padding: 2rem;
    margin-top: 15rem;
    /* border: 2px solid green; */
    /* flex: 1 1 70rem; */
    width: 100%;
    max-width: 100%;

  }
/* .box-wrapper{
    display: flex;
    border: 2px solid orange;
    flex-direction: row-reverse;
    
} */
.imagebox1{
    
    /* border: 2px solid red; */
    width: 100%;
    max-width: 100%;
    height: 60rem;
}

/* .imagebox1 img{
width: 100%;
height: 100%;
max-width: 100%;
object-fit: cover;
object-position: center;



} */

 .imagebox2{ 

    width: 20rem;
    height: 20rem;
    max-width: 20rem;
    
    /* border: 2px solid black; */

    margin-top: -10rem;
    margin-right: -32rem;
    
    
}


}

@media(max-width:450px){
    .imagebox1{
    
        /* border: 2px solid red; */
        width: 100%;
        max-width: 100%;
        height: 30rem;
    }
    .imagebox2{ 

        
    
        margin-top: -12rem;
        margin-right: -25rem;
        
        
    }
}

