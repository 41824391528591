.events{
    /* border: 2px solid red; */
    padding: 5rem 0;
    background-color: #F2F2F2;
}
.events-container{
    /* border: 2px solid blue; */
    display: flex;
}
.events-image{
/* border: 2px solid red; */
width: 70rem;
max-width: 70rem;
height: 80rem;
/* margin: auto; */
}
.events-image img {
width: 100%;
height: 100%;
max-width: 100%;
object-fit: cover;
object-position: center;

}
.events-text{
    /* border: 2px solid orange; */
    width: 70rem;
    max-width: 70rem;
    padding: 5rem;
    
}
.events-text h1{
    font-size: 5rem;
    margin-bottom: 5rem;
}
.events-text h2{
    margin-bottom: 3rem;
    font-weight: lighter;
}
.events-text p{
    font-size: 2rem;
    line-height: 3rem;    
    /* border: 2px solid black; */
    /* width: 90%; */
}

.event-para{
    margin-bottom: 3rem;
}

/* -------- Media Queries-------- */

@media(max-width:1150px){
    .events-text h1{
        font-size: 2rem;
        
    }
}
@media(max-width:950px){
    .events-text p {
        font-size: 1.5rem;
    }
}
@media(max-width:770px){
    .events-text h1{
        font-size: 3rem;
        
    }
    
    .events-container{
        
        flex-wrap: wrap;
    }
    .events-image{
        height: 50rem;
    }
}
@media(max-width:450px){
    
}